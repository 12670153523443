<template>
  <div class="desc">
    <div class="descDoc">
      <div>
        <div><h1>- 国际快递单证代服务 -</h1></div>
        <div>
          <span
            >企业国际快递物流经UPS，FEDEX，DHL ，TNT承运的物品，我司提供向出口申报单证，再经国快递公司向海关申报，降低企业的出口成本，有效提升企业的出口时效。</span
          >
        </div>
      </div>
    </div>
    <div class="process">
      <div><h1>- 运作流程 -</h1></div>
      <img
        src="../../assets/images/contentImgs/process.svg"
        alt="单证出口流程"
      />
    </div>
    <div class="servelist">
      <div><h1>- 优势 -</h1></div>
      <div>
        <div>
          <img src="../../assets/images/contentImgs/world_express.png" alt="货运" />
        </div>
        <div>
          <ul>
            <li>全程一站式服务</li>
            <li>专业化团队，从事报关行业十余年</li>
            <li>质量有保障，提升企业动作效率</li>
            <li>高性价比，专业人员操作，系统化操作，为企业降低成本</li>
            <li>优质服务，一对一的服务专员</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="less" scoped>

@import url("../../assets/css/agentcompnt.less");
.descDoc{
  background: #828d91;
  color: white !important;
}
</style>